import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/dev-site/dev-site/src/templates/Page/index.ts";
import requestJson from './_examples/request';
import * as Schemas from './_schemas/';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Alert = makeShortcode("Alert");
const Example = makeShortcode("Example");
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <div {...{
      "id": "toc-authorization-and-security"
    }}><h2 {...{
        "id": "authorization-and-security",
        "parentName": "div"
      }}>{`Authorization and Security`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`The HTTP `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`Authorization`}</inlineCode>
        {` header is required for authorization. The username is
your
`}
        <a {...{
          "href": "https://www.maxmind.com/en/accounts/current/license-key",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`MaxMind account ID`}</a>
        {`.
The password is your
`}
        <a {...{
          "href": "https://www.maxmind.com/en/accounts/current/license-key",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`MaxMind license key`}</a>
        {`.`}</p>
      <Alert type="warning" mdxType="Alert">
        <p>{`You must be approved for a trial or purchase credit for use with our web
services in order to receive an account ID and license key.`}</p>
      </Alert>
      <p {...{
        "parentName": "div"
      }}>{`We use
`}
        <a {...{
          "href": "https://en.wikipedia.org/wiki/Basic_access_authentication",
          "target": "_blank",
          "rel": "nofollow noopener noreferrer",
          "parentName": "p"
        }}>{`basic HTTP authentication`}</a>
        {`.
The APIs which require authentication are only available via HTTPS. The
credentials are never transmitted unencrypted. If you attempt to access this
service via HTTP, you will receive a `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`403 Forbidden`}</inlineCode>
        {` HTTP response.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`We require TLS 1.2 or greater for all requests to our servers to keep your data
secure.`}</p></div>
    <div {...{
      "id": "toc-service-endpoints"
    }}><h2 {...{
        "id": "service-endpoints",
        "parentName": "div"
      }}>{`Service Endpoints`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`The endpoint for each service is as specified below.`}</p>
      <table {...{
        "parentName": "div"
      }}>

        <thead {...{
          "parentName": "table"
        }}>

          <tr {...{
            "parentName": "thead"
          }}>

            <th {...{
              "parentName": "tr"
            }}>{`Service`}</th>


            <th {...{
              "parentName": "tr"
            }}>{`HTTP Method`}</th>


            <th {...{
              "parentName": "tr"
            }}>{`Endpoint`}</th>

          </tr>

        </thead>


        <tbody {...{
          "parentName": "table"
        }}>

          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`Score`}</td>


            <td {...{
              "parentName": "tr"
            }}><inlineCode {...{
                "parentName": "td"
              }}>{`POST`}</inlineCode></td>


            <td {...{
              "parentName": "tr"
            }}><inlineCode {...{
                "parentName": "td"
              }}>{`https://minfraud.maxmind.com/minfraud/v2.0/score`}</inlineCode></td>

          </tr>


          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`Insights`}</td>


            <td {...{
              "parentName": "tr"
            }}><inlineCode {...{
                "parentName": "td"
              }}>{`POST`}</inlineCode></td>


            <td {...{
              "parentName": "tr"
            }}><inlineCode {...{
                "parentName": "td"
              }}>{`https://minfraud.maxmind.com/minfraud/v2.0/insights`}</inlineCode></td>

          </tr>


          <tr {...{
            "parentName": "tbody"
          }}>

            <td {...{
              "parentName": "tr"
            }}>{`Factors`}</td>


            <td {...{
              "parentName": "tr"
            }}><inlineCode {...{
                "parentName": "td"
              }}>{`POST`}</inlineCode></td>


            <td {...{
              "parentName": "tr"
            }}><inlineCode {...{
                "parentName": "td"
              }}>{`https://minfraud.maxmind.com/minfraud/v2.0/factors`}</inlineCode></td>

          </tr>

        </tbody>

      </table>
      <p {...{
        "parentName": "div"
      }}>{`The `}
        <strong {...{
          "parentName": "p"
        }}>{`minfraud.maxmind.com`}</strong>
        {` hostname automatically picks the data center
geographically closest to you.`}</p></div>
    <div {...{
      "id": "toc-headers"
    }}><h2 {...{
        "id": "headers",
        "parentName": "div"
      }}>{`Headers`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`The `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`Authorization`}</inlineCode>
        {` header is always required. See
`}
        <a {...{
          "href": "#authorization-and-security",
          "parentName": "p"
        }}>{`Authorization and Security`}</a>
        {` for more details.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`The `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`Accept`}</inlineCode>
        {` header for a request is entirely optional. If you do include one,
you must accept one of the following, substituting the `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`[SERVICE-TYPE]`}</inlineCode>
        {` with
either `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`score`}</inlineCode>
        {`, `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`insights`}</inlineCode>
        {`, or `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`factors`}</inlineCode>
        {` as appropriate:`}</p>
      <ul {...{
        "parentName": "div"
      }}>

        <li {...{
          "parentName": "ul"
        }}><inlineCode {...{
            "parentName": "li"
          }}>{`application/json`}</inlineCode></li>


        <li {...{
          "parentName": "ul"
        }}><inlineCode {...{
            "parentName": "li"
          }}>{`application/vnd.maxmind.com-minfraud-[SERVICE TYPE]+json`}</inlineCode></li>


        <li {...{
          "parentName": "ul"
        }}><inlineCode {...{
            "parentName": "li"
          }}>{`application/vnd.maxmind.com-minfraud-[SERVICE TYPE]+json; charset=UTF-8; version=2.0`}</inlineCode></li>

      </ul>
      <p {...{
        "parentName": "div"
      }}>{`A request for any other MIME type will result in a `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`415 Unsupported Media Type`}</inlineCode>
        {`
error.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`If you set the `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`Accept-Charset`}</inlineCode>
        {` header in your client code, you must accept the
`}
        <inlineCode {...{
          "parentName": "p"
        }}>{`UTF-8`}</inlineCode>
        {` character set. If you don't you will receive a `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`406 Not Acceptable`}</inlineCode>
        {`
response.`}</p></div>
    <div {...{
      "id": "toc-bodies"
    }}><h2 {...{
        "id": "bodies",
        "parentName": "div"
      }}>{`Bodies`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`minFraud Score, Factors and Insights share the same request body format. Below
is a full example of the JSON body document. For detailed explainations of each
property within the request body, please refer to the
`}
        <a {...{
          "href": "#object-reference",
          "parentName": "p"
        }}>{`object reference`}</a>
        {` section below.`}</p>
      <Example language="json" mdxType="Example">
        {JSON.stringify(requestJson, null, 2)}
      </Example></div>
    <div {...{
      "id": "toc-object-reference"
    }}><h2 {...{
        "id": "object-reference",
        "parentName": "div"
      }}>{`Object Reference`}</h2>
      <p {...{
        "parentName": "div"
      }}>{`Below are the schema definitions of that make up the minFraud request body
object.`}</p>
      <p {...{
        "parentName": "div"
      }}>{`Each schema definition contains a description of an object, along with a list of
properties that belong to the object. The following information is listed for
each object property:`}</p>
      <ul {...{
        "parentName": "div"
      }}>

        <li {...{
          "parentName": "ul"
        }}>{`name`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`type (`}
          <inlineCode {...{
            "parentName": "li"
          }}>{`array<type>`}</inlineCode>
          {`, `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`boolean`}</inlineCode>
          {`, `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`number`}</inlineCode>
          {`, `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`integer`}</inlineCode>
          {`, `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`object`}</inlineCode>
          {`, `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`string`}</inlineCode>
          {`)`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`description`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`example`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`formatting`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`constraints (format, min/max, max length, etc.)`}</li>


        <li {...{
          "parentName": "ul"
        }}>{`supported services (`}
          <inlineCode {...{
            "parentName": "li"
          }}>{`Score`}</inlineCode>
          {`, `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`Factors`}</inlineCode>
          {`, `}
          <inlineCode {...{
            "parentName": "li"
          }}>{`Insights`}</inlineCode>
          {`)`}</li>

      </ul>
      <p {...{
        "parentName": "div"
      }}>{`Additionally, for `}
        <inlineCode {...{
          "parentName": "p"
        }}>{`object`}</inlineCode>
        {` properties, a link is provided to view a schema
definition that further describes that specific object.`}</p>
      <Schemas.Request />
      <Schemas.RequestDevice />
      <Schemas.RequestEvent />
      <Schemas.RequestAccount />
      <Schemas.RequestEmail />
      <Schemas.RequestBilling />
      <Schemas.RequestShipping />
      <Schemas.RequestPayment />
      <Schemas.RequestCreditCard />
      <Schemas.RequestOrder />
      <Schemas.RequestShoppingCart />
      <Schemas.RequestShoppingCartItem />
      <Schemas.RequestCustomInputs /></div>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      